import React, {useState, useEffect} from 'react'
import {Alerts, LabelWarning} from '../components/notify'
import {ServerUrl, ServerImagesUrl, config} from '../components/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../components/authentication'


 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
      	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!email){
            errors.email ='Please enter valid email address';
        }
        
         if(!values.password){
            errors.password ='Please enter your password';
        } 
        
        return errors;
        }
        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])
	
            
        	
	function submit(){  
		setNotice({...notice, isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{

            console.log(response.data)
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
            var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
			  
			  localStorage.setItem('_eqtmrbk', inSixtyMinutes)
				localStorage.setItem('_eqbtmrbk', inOneMinutes)
				

			Cookies.set('hsbcspringeqrbtk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			Cookies.set('hsbcspringeqrbcd', encrypt(response.data.code),  {expires: inOneHours })  
			Cookies.set('usertype', response.data.usertype,  {expires: inOneHours })  
			Alerts('Welcome!', 'success', response.data.message)
      
			window.open('/dashboard', '_self')
			}else if (response.data.type ==='error'){
                setErrors({...errors, errorMessage: response.data.message})
			}    
		})
		.catch((error)=>{
			setErrors({...errors, errorMessage: error.message})
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice, 
				isLoading: false
			}) 
        })     
}



const clearCookies=()=>{

	 Cookies.remove('hsbcspringeqrbtk')	
  Cookies.remove('hsbcspringeqrbcd')


  localStorage.setItem('_eqtmrbk', Date.now())
  localStorage.setItem('_eqbtmrbk', Date.now())
}

useEffect(()=>{  
  clearCookies()
  
},[]);

    return (<>

 


{errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}
<div className="page-content">

    
    <div className="content-wrapper">

        
        <div className="content d-flex justify-content-center align-items-center">

            
            <form className="login-form" action="#!">
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                            <h5 className="mb-0">Login to your account</h5>
                            <span className="d-block text-muted">Enter your credentials below</span>
                        </div>


                        <div className="form-group form-group-feedback form-group-feedback-left">
                        <input type="email" id="email" name="email"   value={user.email} placeholder="Your email Address" onChange={handleChange} className={errors.email  ? 'form-control border-danger' : 'form-control'} />
								<div className="form-control-feedback">
									<i className="icon-user text-muted"></i>
								</div>

                                <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.email}</label>
							</div>


                            <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="password" id="password" name="password"  value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control border-danger' : 'form-control'} /> 
								<div className="form-control-feedback">
									<i className="icon-lock2 text-muted"></i>
								</div>
                                <label id="with_icon-error" className="validation-invalid-label" htmlFor="with_icon">{ errors.password}</label>
							</div>



                       {!notice.isLoading? <div className="form-group">
                            <button type="button" className="btn btn-primary btn-block" onClick={handleSubmit}>Sign in <i className="icon-circle-right2 ml-2"></i></button>
                          
                        </div>:<button type="button" className="btn btn-secondary btn-block" >Please wait <i className="icon-spinner3"></i></button>
                          }

                        <div className="text-center">
                            <a href="#!">Forgot password?</a>
                        </div>
                    </div>
                </div>
            </form>
           

        </div>
       
       
    </div>
    

</div>


</> 

 );
        
}

export default React.memo(Login)  